<div class="sidebar" id="sidebar">
    <div class="sidebar-inner slimscroll">
        <div id="sidebar-menu" class="sidebar-menu">
            <div class="">
                <div   class="card-header collapse-header-for-mastermanagement headerCss"
                    id="headingtask">
                    <h5 class="mb-0">
                        <button class="btn btn-link buttonCss" data-toggle="collapse" data-target="#dashBoardColla"
                            aria-expanded="false" aria-controls="collapseOne">
                            <!-- <i class="fa fa-users"></i> -->
                            <span class="comncl_menuhd fontColor menuCss"> Dashboard</span>
                            <i class="fa fa-angle-double-right fontColor menuCss agfxchgvjhgv"></i>
                        </button>
                    </h5>
                </div>
                <div id="dashBoardColla" class="collapse headerTask" aria-labelledby="headingtask"
                    >
                    <div class="" >
                 
                            <ul class="menu-content " >
                                <!-- <li class="cursorData"><a style="color: grey;" (click)="OrganizationUser()" class="abc">Organization User</a></li> -->

                                <li class="cursorData"><a (click)="dashBoard()" class="abc">Dashboard <span><i class="fa fa-dashboard iconCss"></i></span> </a>
                               
                                </li>
                               
                           
                                <!-- <li class="cursorData"><a style="color: grey;" (click)="onClickSynchro()" class="abc">Synchronization</a></li> -->
                            </ul>
                    
                    </div>
                </div>
            </div>



            <div class="">
                <div   class="card-header collapse-header-for-mastermanagement headerCss"
                    id="headingtask">
                    <h5 class="mb-0">
                        <button  class="btn btn-link buttonCss" data-toggle="collapse" data-target="#collapseEmployees"
                            aria-expanded="false" aria-controls="collapseOne">
                            <!-- <i class="fa fa-users"></i> -->
                            <span class="comncl_menuhd fontColor menuCss"> Employees</span>
                            <i class="fa fa-angle-double-right fontColor menuCss agfxchgvjh"></i>
                        </button>
                    </h5>
                </div>
                <div id="collapseEmployees" class="collapse headerTask" aria-labelledby="headingtask"
                    >
                    <div class="" >
                 
                            <ul class="menu-content " >
                                <!-- <li class="cursorData"><a style="color: grey;" (click)="OrganizationUser()" class="abc">Organization User</a></li> -->

                                <li class="cursorData"><a (click)="attendanceClickEmployee()" class="abc">Attendance (Employee) <span><i class="fas fa-tasks iconCss"></i></span> </a>
                               
                                </li>
                                <li class="cursorData"><a class="active" (click)="attendanceClick()"  class="abc">Attendance (Admin) <span> <i class="fas fa-tasks iconCss"></i> </span></a></li>
                                <li class="cursorData"><a  (click)="onClick()"  class="abc">Markers <span>  <i class="fas fa-chalkboard-teacher iconCss"></i></span> </a> </li>
                                <li class="cursorData"><a  (click)="onClickCandi()"  class="abc">Candidates <span><i class="fas fa-users iconCss"></i></span></a></li>

                                <li class="cursorData"><a  (click)="onClickGroup()" class="abc">Groups <span><i class="fa fa-users iconCss" aria-hidden="true"></i></span></a></li>

                           
                                <!-- <li class="cursorData"><a style="color: grey;" (click)="onClickSynchro()" class="abc">Synchronization</a></li> -->
                            </ul>
                    
                    </div>
                </div>
            </div>


            <div class="">
                <div   class="card-header collapse-header-for-mastermanagement headerCss"
                    id="headingtaskdata">
                    <h5 class="mb-0">
                        <button   class="btn btn-link buttonCss" data-toggle="collapse" data-target="#collapseEmployeesDta"
                            aria-expanded="false" aria-controls="collapseOne">
                            <!-- <i class="fa fa-users"></i> -->
                            <span class="comncl_menuhd fontColor menuCss"> Master Management</span>
                            <i class="fa fa-angle-double-right fontColor menuCss sbsd"></i>
                        </button>
                    </h5>
                </div>
                <div id="collapseEmployeesDta" class="collapse" aria-labelledby="headingtask"
                    style="  margin-left: 5px; ">
                    <div class="" >
                 
                            <ul class="menu-content" >
                                <li class="cursorData"><a  (click)="onClickErp()" class="abc">ERP 

                                    <span> <i class="fa fa-database iconCss"></i> </span>
                                </a></li>


                                <li class="cursorData"><a  (click)="onClickConfig()" class="abc">Config 

                                    <span> <i class="fa fa-database iconCss"></i> </span>
                                </a></li>

                                <li class="cursorData"><a (click)="onClickSite()" class="abc">Sites
                                    <span> <i class="fa fa-building iconCss"></i> </span>
                                </a></li>

                                <li class="cursorData"><a (click)="onClickCode()" class="abc">QR code
                                    <span> <i class="fa fa-building iconCss"></i> </span>
                                </a></li>

                                
                                <li class="cursorData"><a  (click)="onClickBulkUpload()" class="abc">Bulk Upload
                                    <span> <i class="fa fa-upload iconCss"></i> </span>
                                </a></li>
                                <li class="cursorData"><a (click)="onClickMyUpload()" class="abc">My Uploads
                                    <span> <i class="fa fa-upload iconCss"></i> </span>
                                </a></li>

                                <!-- <li class="cursorData"><a (click)="onClickAssignLocation()" class="abc">Assign Location
                                    <span> <i class="fa fa-upload iconCss"></i> </span>
                                </a></li> -->

                                <li class="cursorData"><a (click)="onClickReports()" class="abc">Reports
                                    <span> <i class="fas fa-file-contract iconCss"></i> </span>
                                </a></li>
                          
                            </ul>
                    
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>