import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SecureComponent } from './secure/secure.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AuthGuard } from './gaurd/auth.guard';
const routes: Routes = [
  { path: '', redirectTo: 'secure', pathMatch: 'full' },
  { path: 'secure', canActivate: [ AuthGuard ], component: SecureComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: '404', component: NotFoundComponent },
  { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'header', loadChildren: () => import('./header/header.module').then(m => m.HeaderModule) },
  { path: 'sidebar', loadChildren: () => import('./sidebar/sidebar.module').then(m => m.SidebarModule) },
  { path: 'students', loadChildren: () => import('./students/students.module').then(m => m.StudentsModule) },
  { path: 'attendance', loadChildren: () => import('./attendance/attendance.module').then(m => m.AttendanceModule) },
  { path: 'attendance-employees', loadChildren: () => import('./attendance-employees/attendance-employees.module').then(m => m.AttendanceEmployeesModule) },
  { path: 'candidates', loadChildren: () => import('./candidates/candidates.module').then(m => m.CandidatesModule) },
  { path: 'group', loadChildren: () => import('./group/group.module').then(m => m.GroupModule) },
  { path: 'list-group', loadChildren: () => import('./group-list-add/list-group/list-group.module').then(m => m.ListGroupModule) },
  { path: 'add-group', loadChildren: () => import('./group-list-add/add-group/add-group.module').then(m => m.AddGroupModule) },
  { path: 'add-time-table', loadChildren: () => import('./time-table/add-time-table/add-time-table.module').then(m => m.AddTimeTableModule) },
  { path: 'list-marker-candidates', loadChildren: () => import('./list-marker-candidates/list-marker-candidates/list-marker-candidates.module').then(m => m.ListMarkerCandidatesModule) },
  { path: 'add-candidates-timetable', loadChildren: () => import('./add-candidates-in-timetable/add-candidates-timetable/add-candidates-timetable.module').then(m => m.AddCandidatesTimetableModule) },
  { path: 'add-candidtes-inner-user', loadChildren: () => import('./add-candidates-in-timetable/add-candidtes-inner-user/add-candidtes-inner-user.module').then(m => m.AddCandidtesInnerUserModule) },
  { path: 'add-candidtes-inner-candi', loadChildren: () => import('./add-candidates-in-timetable/add-candidtes-inner-candi/add-candidtes-inner-candi.module').then(m => m.AddCandidtesInnerCandiModule) },
  { path: 'view-marker-profile', loadChildren: () => import('./list-marker-candidates/view-marker-profile/view-marker-profile.module').then(m => m.ViewMarkerProfileModule) },
  { path: 'view-candidate-profile', loadChildren: () => import('./list-marker-candidates/view-candidate-profile/view-candidate-profile.module').then(m => m.ViewCandidateProfileModule) },
  { path: 'add-marker-inner-marker', loadChildren: () => import('./add-candidates-in-timetable/add-marker-inner-marker/add-marker-inner-marker.module').then(m => m.AddMarkerInnerMarkerModule) },
  { path: 'add-site', loadChildren: () => import('./master-management/site/add-site/add-site.module').then(m => m.AddSiteModule) },
  { path: 'list-site', loadChildren: () => import('./master-management/site/list-site/list-site.module').then(m => m.ListSiteModule) },
  { path: 'list-building', loadChildren: () => import('./master-management/building/list-building/list-building.module').then(m => m.ListBuildingModule) },
  { path: 'add-building', loadChildren: () => import('./master-management/building/add-building/add-building.module').then(m => m.AddBuildingModule) },
  { path: 'add-floor', loadChildren: () => import('./master-management/floor/add-floor/add-floor/add-floor.module').then(m => m.AddFloorModule) },
  { path: 'list-floor', loadChildren: () => import('./master-management/floor/add-floor/list-floor/list-floor/list-floor.module').then(m => m.ListFloorModule) },
  { path: 'add-room', loadChildren: () => import('./master-management/room/add-room/add-room/add-room.module').then(m => m.AddRoomModule) },
  { path: 'list-room', loadChildren: () => import('./master-management/room/list-room/list-room/list-room.module').then(m => m.ListRoomModule) },
  { path: 'add-erp', loadChildren: () => import('./erp/add-erp/add-erp.module').then(m => m.AddErpModule) },
  { path: 'list-erp', loadChildren: () => import('./erp/list-erp/list-erp.module').then(m => m.ListErpModule) },
  { path: 'add-candidates-in-class', loadChildren: () => import('./add-candidates-in-class/add-candidates-in-class/add-candidates-in-class.module').then(m => m.AddCandidatesInClassModule) },
  { path: 'list-candidates-in-class', loadChildren: () => import('./add-candidates-in-class/list-candidates-in-class/list-candidates-in-class.module').then(m => m.ListCandidatesInClassModule) },
  { path: 'add-candidatess-in-class', loadChildren: () => import('./add-candidates-in-class/add-candidatess-in-class/add-candidatess-in-class.module').then(m => m.AddCandidatessInClassModule) },
  { path: 'bulk-upload', loadChildren: () => import('./bulk-upload/bulk-upload.module').then(m => m.BulkUploadModule) },
  { path: 'my-upload', loadChildren: () => import('./my-upload/my-upload.module').then(m => m.MyUploadModule) },
  { path: 'file-upload-details', loadChildren: () => import('./file-upload-details/file-upload-details.module').then(m => m.FileUploadDetailsModule) },
  { path: 'progress-details', loadChildren: () => import('./progress-details/progress-details.module').then(m => m.ProgressDetailsModule) },
  { path: 'view-group/:id', loadChildren: () => import('./view-group/view-group.module').then(m => m.ViewGroupModule) },
  { path: 'view-timetable', loadChildren: () => import('./view-timetable/view-timetable.module').then(m => m.ViewTimetableModule) },
  { path: 'view-candidate/:id', loadChildren: () => import('./view-candidate/view-candidate.module').then(m => m.ViewCandidateModule) },
  { path: 'add-timetable-dropdown', loadChildren: () => import('./time-table-dropdown/add-timetable-dropdown/add-timetable-dropdown.module').then(m => m.AddTimetableDropdownModule) },
  { path: 'view-time-table-list', loadChildren: () => import('./view-time-table-list/view-time-table-list/view-time-table-list.module').then(m => m.ViewTimeTableListModule) },
  { path: 'add-group-candidate', loadChildren: () => import('./view-candidate/add-group-candidate/add-group-candidate.module').then(m => m.AddGroupCandidateModule) },
  { path: 'add-timetable-in-candidate', loadChildren: () => import('./view-candidate/add-timetable-in-candidate/add-timetable-in-candidate.module').then(m => m.AddTimetableInCandidateModule) },
  { path: 'view-site', loadChildren: () => import('./master-management/site/view-site/view-site.module').then(m => m.ViewSiteModule) },
  { path: 'view-building', loadChildren: () => import('./master-management/building/view-building/view-building.module').then(m => m.ViewBuildingModule) },
  { path: 'view-floor', loadChildren: () => import('./master-management/floor/add-floor/view-floor/view-floor.module').then(m => m.ViewFloorModule) },
  { path: 'organization', loadChildren: () => import('./organization/organization.module').then(m => m.OrganizationModule) },
  { path: 'view-room', loadChildren: () => import('./master-management/room/view-room/view-room.module').then(m => m.ViewRoomModule) },
  { path: 'add-candidates', loadChildren: () => import('./candidates/add-candidates/add-candidates/add-candidates.module').then(m => m.AddCandidatesModule) },
  { path: 'add-candidates-data', loadChildren: () => import('./add-candidates-data/add-candidates-data.module').then(m => m.AddCandidatesDataModule) },
  { path: 'add-marker', loadChildren: () => import('./list-marker-candidates/add-marker/add-marker.module').then(m => m.AddMarkerModule) },
  { path: 'view-sync', loadChildren: () => import('./synchronization/view-sync/view-sync.module').then(m => m.ViewSyncModule) },
  { path: 'view-sync-profile', loadChildren: () => import('./synchronization/view-sync-profile/view-sync-profile.module').then(m => m.ViewSyncProfileModule) },
  { path: 'view-timetable-profile', loadChildren: () => import('./synchronization/timetable/view-timetable-profile/view-timetable-profile.module').then(m => m.ViewTimetableProfileModule) },
  { path: 'view-sync-profile-marker', loadChildren: () => import('./synchronization/view-sync-profile-marker/view-sync-profile-marker.module').then(m => m.ViewSyncProfileMarkerModule) },
  { path: 'view-sync-profile-attendance', loadChildren: () => import('./synchronization/view-sync-profile-attendance/view-sync-profile-attendance.module').then(m => m.ViewSyncProfileAttendanceModule) },
  { path: 'view-request-json', loadChildren: () => import('./json-data/view-request-json/view-request-json.module').then(m => m.ViewRequestJsonModule) },
  { path: 'view-response-json', loadChildren: () => import('./json-data/view-response-json/view-response-json.module').then(m => m.ViewResponseJsonModule) },
  { path: 'view-recurrence', loadChildren: () => import('./recurrence-task/view-recurrence/view-recurrence.module').then(m => m.ViewRecurrenceModule) },
  { path: 'view-erp', loadChildren: () => import('./erp/view-erp/view-erp.module').then(m => m.ViewErpModule) },
  { path: 'add-attendance', loadChildren: () => import('./attendance/add-attendance/add-attendance.module').then(m => m.AddAttendanceModule) },
  { path: 'add-config', loadChildren: () => import('./config/add-config/add-config.module').then(m => m.AddConfigModule) },
  { path: 'view-config', loadChildren: () => import('./config/view-config/view-config.module').then(m => m.ViewConfigModule) },
  { path: 'list-config', loadChildren: () => import('./config/list-config/list-config.module').then(m => m.ListConfigModule) },
  { path: 'mat-table', loadChildren: () => import('./mat-table/mat-table/mat-table.module').then(m => m.MatTablesModule) },
  { path: 'corporate-register', loadChildren: () => import('./corporate-register/corporate-register/corporate-register.module').then(m => m.CorporateRegisterModule) },
  { path: 'validate-otp', loadChildren: () => import('./corporate-register/validate-otp/validate-otp.module').then(m => m.ValidateOtpModule) },
  { path: 'org-details', loadChildren: () => import('./corporate-register/org-details/org-details.module').then(m => m.OrgDetailsModule) },
  { path: 'invite-users', loadChildren: () => import('./corporate-register/invite-users/invite-users.module').then(m => m.InviteUsersModule) },
  { path: 'add-timetable-to-marker', loadChildren: () => import('./add-timetable-to candidates/add-timetable-to-marker/add-timetable-to-marker.module').then(m => m.AddTimetableToMarkerModule) },
  { path: 'code-generation', loadChildren: () => import('./QR-code-generation/code-generation/code-generation.module').then(m => m.CodeGenerationModule) },
  { path: 'list-code-generation', loadChildren: () => import('./QR-code-generation/list-code-generation/list-code-generation.module').then(m => m.ListCodeGenerationModule) },
  { path: 'view-reports', loadChildren: () => import('./reports/view-reports/view-reports.module').then(m => m.ViewReportsModule) },
  { path: 'assign-location', loadChildren: () => import('./master-management/assign-location/assign-location/assign-location.module').then(m => m.AssignLocationModule) },
  { path: 'advance-filters', loadChildren: () => import('./reports/advance-filters/advance-filters.module').then(m => m.AdvanceFiltersModule) },
  { path: '**', redirectTo: '404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
