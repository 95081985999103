import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { AuthService } from '../service/auth.service';
import jwt_decode from "jwt-decode";
@Component({
  selector: 'app-secure',
  templateUrl: './secure.component.html',
  styleUrls: ['../app.component.css']
})
export class SecureComponent implements OnInit {

  message = '';
  isLoadingResults = false;
  decode:any;
  token:any;
  constructor(private authService: AuthService, private router: Router) {
    this.token = localStorage.getItem("access_token");
    var decoded = jwt_decode(this.token);
    this.decode = JSON.parse(JSON.stringify(decoded));
   }

  ngOnInit(): void {
    debugger
    this.isLoadingResults = true;
    this.authService.secured()
      .subscribe((data: any) => {
        this.message = data;
        console.log(data);
        this.isLoadingResults = false;
      });
if(this.decode != undefined || this.decode != '' || this.decode != null)
{    
    this.router.navigate(['/dashboard'])
}
  }

  logout(): void {
    this.authService.logout();
    this.router.navigate(['/login']).then(_ => console.log('Logout'));
  }

}