import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GroupServiceService {

  // private API_URL = 'http://face.equaze.in:8080/mobileface/attendance/v1';
  private API_URL = environment.baseUrl;
 
  constructor(private httpClient: HttpClient) { }

  public listGroupData(pageNumber: any, numberOfRecords: any, order: any, body: any) {
    debugger;
    return this.httpClient.post(this.API_URL + "/group/groupFindAll?numberOfRecords="+numberOfRecords+"&order="+order+"&page="+pageNumber+"&pagination=true", body)
  }
  public saveGroup(json: any) {
    debugger
    return this.httpClient.post(this.API_URL + "/group/organization", json);
  }

  public listTimeTableData(pageNumber: any, numberOfRecords: any, order: any, body: any) {
    debugger;
    return this.httpClient.post(this.API_URL + "/timeTableClass/timeTableClass?numberOfRecords="+numberOfRecords+"&order="+order+"&page="+pageNumber+"&pagination=true", body)
  }

   dateWiseData(body)
   {
    return this.httpClient.post(this.API_URL + "/timeTableUser/dateWiseReport", body);
   }


  public listTimeTableDataFalse(body: any) {
    debugger;
    return this.httpClient.post(this.API_URL + "/timeTableClass/timeTableClass?numberOfRecords=10&order=startTime_ASC&page=0&pagination=false", body)
  }

  public sinkData(json: any) {
    debugger
    return this.httpClient.post(this.API_URL + "/erp/erpSink", json);
  }

  public getmailData(groupId){
    return this.httpClient.get(this.API_URL + "/group/group/" + groupId);
  }

  public getmailDataCandidate(candidateId){
    return this.httpClient.get(this.API_URL + "/candidate/candidate/" + candidateId);
  }
  public sinkTimeTable(body){
    return this.httpClient.post(this.API_URL + "/groupCandidate/group-sink",body);
  }

insertTimetableMarker(body)
{
  return this.httpClient.post(this.API_URL + "/group/addMarkerInGroup",body)
}



  getOrganizationId(organizationId)
  {
    return this.httpClient.get(this.API_URL + "/organization/organization/" + organizationId);
  }

assignLocationToGroup(groupId)
{
  return this.httpClient.post(this.API_URL + "/group/organization", groupId);

}

}
